import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'login'
  },
  {
    path: '/home',
    component: () => import('@/views/Home/Home.vue'),
    name: 'home',
    redirect: '/home/main',
    children: [
      {
        path: 'main',
        component: () => import('@/views/Main/Main.vue'),
        name: 'main'
      },
      {
        path: 'register',
        component: () => import('@/views/Register/Register.vue'),
        name: 'register'
      },
      {
        path: 'ArtDetails1',
        component: () => import('@/views/ArtDetails/ArtDetails1.vue'),
        name: 'ArtDetails1'
      },
      {
        path: 'ArtDetails2',
        component: () => import('@/views/ArtDetails/ArtDetails2.vue'),
        name: 'ArtDetails2'
      },
      {
        path: 'ArtDetails3',
        component: () => import('@/views/ArtDetails/ArtDetails3.vue'),
        name: 'ArtDetails3'
      },
      {
        path: 'ArtDetails4',
        component: () => import('@/views/ArtDetails/ArtDetails4.vue'),
        name: 'ArtDetails4'
      },
      {
        path: 'ArtDetails5',
        component: () => import('@/views/ArtDetails/ArtDetails5.vue'),
        name: 'ArtDetails5'
      },
      {
        path: 'ArtDetails6',
        component: () => import('@/views/ArtDetails/ArtDetails6.vue'),
        name: 'ArtDetails6'
      },
      {
        path: 'ArtDetails7',
        component: () => import('@/views/ArtDetails/ArtDetails7.vue'),
        name: 'ArtDetails7'
      },
      {
        path: 'ArtDetails8',
        component: () => import('@/views/ArtDetails/ArtDetails8.vue'),
        name: 'ArtDetails8'
      },
      {
        path: 'ArtDetails11',
        component: () => import('@/views/ArtDetails/ArtDetails11.vue'),
        name: 'ArtDetails11'
      },
      {
        path: 'MainFloor1',
        component: () => import('@/views/MainFloor1/MainFloor1.vue'),
        name: 'MainFloor1'
      },
      {
        path: 'MainFloor2',
        component: () => import('@/views/MainFloor1/MainFloor2.vue'),
        name: 'MainFloor2'
      },
      {
        path: 'MainFloor3',
        component: () => import('@/views/MainFloor1/MainFloor3.vue'),
        name: 'MainFloor3'
      },
      {
        path: 'MainFloor4',
        component: () => import('@/views/MainFloor1/MainFloor4.vue'),
        name: 'MainFloor4'
      },
      {
        path: 'MainFloor5',
        component: () => import('@/views/MainFloor1/MainFloor5.vue'),
        name: 'MainFloor5'
      },
      {
        path: 'MainFloor6',
        component: () => import('@/views/MainFloor1/MainFloor6.vue'),
        name: 'MainFloor6'
      },
      {
        path: 'MainFloor7',
        component: () => import('@/views/MainFloor1/MainFloor7.vue'),
        name: 'MainFloor7'
      },
      {
        path: 'MainFloor8',
        component: () => import('@/views/MainFloor1/MainFloor8.vue'),
        name: 'MainFloor8'
      },
      {
        path: 'MainFloor9',
        component: () => import('@/views/MainFloor1/MainFloor9.vue'),
        name: 'MainFloor9'
      },
      {
        path: 'ComicDetails1',
        component: () => import('@/views/ComicDetails/ComicDetails1.vue'),
        name: 'ComicDetails1'
      },
      {
        path: 'ComicDetails2',
        component: () => import('@/views/ComicDetails/ComicDetails2.vue'),
        name: 'ComicDetails2'
      },
      {
        path: 'ComicDetails3',
        component: () => import('@/views/ComicDetails/ComicDetails3.vue'),
        name: 'ComicDetails3'
      },
      {
        path: 'ComicDetails4',
        component: () => import('@/views/ComicDetails/ComicDetails4.vue'),
        name: 'ComicDetails4'
      },
      {
        path: 'MainSeckill1',
        component: () => import('@/views/MainSeckill1/MainSeckill1.vue'),
        name: 'MainSeckill1'
      },
      {
        path: 'MainSeckill2',
        component: () => import('@/views/MainSeckill1/MainSeckill2.vue'),
        name: 'MainSeckill2'
      },
      {
        path: 'MainSeckill3',
        component: () => import('@/views/MainSeckill1/MainSeckill3.vue'),
        name: 'MainSeckill3'
      },
      {
        path: 'MainSeckill4',
        component: () => import('@/views/MainSeckill1/MainSeckill4.vue'),
        name: 'MainSeckill4'
      },
      {
        path: 'TopicDiscuss1',
        component: () => import('@/views/TopicDiscuss1/TopicDiscuss1.vue'),
        name: 'TopicDiscuss1'
      },
      {
        path: 'TopicDiscuss2',
        component: () => import('@/views/TopicDiscuss1/TopicDiscuss2.vue'),
        name: 'TopicDiscuss2'
      },
      {
        path: 'TopicDiscuss3',
        component: () => import('@/views/TopicDiscuss1/TopicDiscuss3.vue'),
        name: 'TopicDiscuss3'
      },
      {
        path: 'authorSpecial1',
        component: () => import('@/views/authorList/authorSpecial1.vue'),
        name: 'authorSpecial1'
      },
      {
        path: 'authorSpecial2',
        component: () => import('@/views/authorList/authorSpecial2.vue'),
        name: 'authorSpecial2'
      },
      {
        path: 'authorSpecial3',
        component: () => import('@/views/authorList/authorSpecial3.vue'),
        name: 'authorSpecial3'
      },
      {
        path: 'authorSpecial4',
        component: () => import('@/views/authorList/authorSpecial4.vue'),
        name: 'authorSpecial4'
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
